/** список логинов */
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useRouteMatch, useLocation, useParams } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import Markdown from "markdown-to-jsx";
import moment from "moment";
import qs from "query-string";
import { setOnChange } from "components/special";
import { adminAPI } from "redux/api";
import { history } from "redux/store";
import { Uploader, ImagePreview, VideoPreview, AudioPreview } from "components/standart";
import { useIntl } from "react-intl";
import { LoadingIcon } from "components/special";
import { ShowDuration, ShowShortDuration } from "components/special/durations";
import ChannelMessageForm from "../../Channels/Messages/Form";

import { apiUrls } from "./init";
import { useToggle } from "react-use";

const { api } = adminAPI.actions;

const getCastMessages = api.get(apiUrls.castMessages);
const setCastCover = api.post(apiUrls.castSetCover);
const getCastOrderedMessages = api.get(apiUrls.castOrderedMessages);
const getCastSkippedMessages = api.get(apiUrls.castSkippedMessages);
const getCastDeletedMessages = api.get(apiUrls.castDeletedMessages);
const GeneratedCastInfo = (props) => {
  const { castInfo, setCastInfo, refreshHandler } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const { url, path } = useRouteMatch();
  const intl = useIntl();
  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });
  const [castMessages, setCastMessages] = useState({ total: 0 });
  const [orderedMessages, setOrderedMessages] = useState({ total: 0 });
  const [castSkippedMessages, setCastSkippedMessages] = useState({ total: 0 });
  const [castDeletedMessages, setCastDeletedMessages] = useState({ total: 0 });
  const [query, setQuery] = useState({});

  useEffect(() => {
    setQuery({ ...params, castId: castInfo._id });
  }, [castInfo]);

  const setCoverHandler = (fileId) => {
    const body = { fileId };
    const params = { castId: castInfo._id };
    dispatch(
      setCastCover(
        { params, body },
        {
          onSuccess(cast) {
            //
            setCastInfo(cast);
          },
        }
      )
    );
  };

  useEffect(() => {
    const onSuccess = (body) => {
      setCastMessages(body);
    };
    const onRequest = () => {
      // setCastMessages({ total: -1 });
    };

    if (query.castId) {
      dispatch(
        getCastMessages({ params: { ...params, ...query }, query }, { onSuccess, onRequest })
      );
      dispatch(
        getCastSkippedMessages(
          { params: { ...params, ...query }, query },
          {
            onSuccess(body) {
              setCastSkippedMessages(body);
            },
            onRequest() {
              // setCastSkippedMessages({ total: -1 });
            },
          }
        )
      );
      dispatch(
        getCastOrderedMessages(
          { params: { ...params, ...query }, query },
          {
            onSuccess(body) {
              setOrderedMessages(body);
            },
            onRequest() {},
          }
        )
      );
      dispatch(
        getCastDeletedMessages(
          { params: { ...params, ...query }, query },
          {
            onSuccess(body) {
              setCastDeletedMessages(body);
            },
            onRequest() {
              // setCastDeletedMessages({ total: -1 });
            },
          }
        )
      );
    }
  }, [query, castInfo]);

  return (
    <div>
      <div className="h4">
        {castInfo.channel?.name || castInfo.category?.name || "Главное за день"} {/* */}
        {" | "} {/* */}
        {castInfo.runtime?.timeTitle} {moment(castInfo.createdAt).format("DD.MM")} {/* */}
        {" | "} {/* */}
        <ShowDuration {...castInfo} />
      </div>
      <div className="row pb-3">
        <div className="col -text-nowrap">
          <CastChangeStatusButton {...{ castInfo, refreshHandler }} successHandler={setCastInfo} />
          {/* 
          <CastCompilationButton {...{ castInfo, refreshHandler }} successHandler={setCastInfo} />
           */}
        </div>
        <div className="col -text-nowrap">
          {/* <SpecialButtonsBlock {...{ castInfo, refreshHandler }} /> */}
        </div>
      </div>
      <div>
        <div>
          {castInfo.fileId ? (
            <ImagePreview
              file={{ _id: castInfo.fileId }}
              style={{ width: "200px", height: "200px" }}
            />
          ) : (
            <b>Нет картинки</b>
          )}
        </div>
        <div className="py-3">
          <CastRefreshImageButton {...{ castInfo }} successHandler={setCastInfo} />
          <Uploader
            className="btn-success btn-sm mx-2"
            successHandler={([file]) => setCoverHandler(file._id)}
            imagesOnly="true"
            multiple={false}
          />
        </div>
      </div>
      <div className="py-2">
        {castInfo.introFileId ? <AudioPreview file={{ _id: castInfo.introFileId }} /> : null}
        <CastRefreshIntroButton {...{ castInfo }} successHandler={setCastInfo} />
      </div>
      {/* показывать только для выпусков, у которых нет ни категории, ни канала (главное за день) */}
      {castInfo.categoryId || castInfo.channelId ? null : (
        <div className="py-2">
          <CastScoringTextBlock {...{ castInfo }} successHandler={setCastInfo} />
        </div>
      )}
      <CastPublicationInfo {...{ castInfo }} />
      <div className="py-2">
        {_.slice(castInfo.keywords, 0, 3).map((text, index) => {
          return (
            <span className={`mr-1`} key={index}>
              {text}
              {"; "}
            </span>
          );
        })}
        {/* 
        {_.sortBy(castMessages.data, (message) => -message.score).map((message, index) => {
          return (
            <span className={`mr-1 ${index < 5 ? "font-weight-bold" : ""}`}>
              {message.title}
              {";"}
            </span>
          );
        })}
              */}
        {castInfo.categoryId || castInfo.channelId ? (
          <>
            <ShowMessagesList
              title="Принятые сообщения"
              castInfo={castInfo}
              deleteHandler={castInfo.publishedAt ? null : setCastInfo}
              allowEditValues={true}
              allowCheckBoxes={true}
              editHandler={refreshHandler}
              messagesData={castMessages}
            />
            <ShowMessagesList
              title="Низкая оценка"
              castInfo={castInfo}
              messagesData={castSkippedMessages}
              includeHandler={castInfo.publishedAt ? null : refreshHandler} // можно добавлять только в неопубликованный выпуск
            />
            <ShowMessagesList
              title="Дубликаты сообщений"
              messagesData={castDeletedMessages}
              castInfo={castInfo}
              notDuplicateHandler={castInfo.publishedAt ? null : refreshHandler} // можно добавлять только в неопубликованный выпуск
            />
          </>
        ) : (
          <>
            <ShowMessagesList
              title="Упорядоченные сообщения"
              castInfo={castInfo}
              deleteHandler={null}
              allowEditValues={false}
              editHandler={null}
              messagesData={orderedMessages}
            />
          </>
        )}
        {/* {_.join(castInfo.keywords, "; ")} */}
      </div>
      <div className="row">
        <div className="col -text-nowrap">
          <CastChangeStatusButton {...{ castInfo, refreshHandler }} successHandler={setCastInfo} />
          {/* 
          <CastCompilationButton {...{ castInfo, refreshHandler }} successHandler={setCastInfo} />
           */}
        </div>
      </div>
    </div>
  );
};

const ShowMessagesList = (props) => {
  const {
    title,
    messagesData,
    deleteHandler,
    includeHandler,
    notDuplicateHandler,
    editHandler,
    castInfo,
    allowEditValues,
    allowCheckBoxes,
  } = props;

  const [checkBoxes, setCheckboxes] = useState({});
  const toggleCheckboxes = (value) => {
    setCheckboxes({ ...checkBoxes, [value]: !checkBoxes[value] });
  };

  return messagesData.total > 0 ? (
    <div>
      <div className="h4 mt-3">
        {title} ({messagesData.total})
      </div>

      <Table responsive style={{ position: "relative" }}>
        <thead>
          <tr>
            <th>№</th>
            <th>
              <IntlMessages id="Messages.channel" />
            </th>
            <th className="col-4">
              <IntlMessages id="Messages.title" />
            </th>
            <th>
              <IntlMessages id="Messages.text" />
            </th>
            <th>
              <IntlMessages id="Messages.score" />
            </th>

            <th>
              <IntlMessages id="Messages.ident" />
            </th>

            <th>
              <IntlMessages id="time" />
            </th>
            <th>
              <IntlMessages id="date" />
            </th>

            {/* <th>
              <IntlMessages id="Messages.status" />
            </th> */}
            {/* <th>
            <IntlMessages id="Messages.date" />
          </th> */}
            <th className="text-nowrap">
              {deleteHandler && allowCheckBoxes && _.values(checkBoxes).filter(Boolean).length ? (
                <DeleteCastMessageButton
                  {...{ castInfo }}
                  messagesId={_.keys(checkBoxes).filter((value) => checkBoxes[value])}
                  successHandler={deleteHandler}
                />
              ) : null}
              {/* <Finder.search {...{ query, url }} className="mr-1" /> */}
            </th>
          </tr>
        </thead>

        <tbody>
          {_.map(messagesData.data, (message, key) => {
            return (
              <ShowMessagesTr
                {...{
                  message,
                  castInfo,
                  deleteHandler,
                  includeHandler,
                  notDuplicateHandler,
                  editHandler,
                  allowEditValues,
                  allowCheckBoxes,
                  checkBoxes,
                  toggleCheckboxes,
                  index: key,
                }}
                key={message._id}
              />
            );
          })}
        </tbody>
      </Table>
    </div>
  ) : null;
};

const ShowMessagesTr = (props) => {
  const intl = useIntl();
  const [message, setMessage] = useState(props.message);
  const { deleteHandler, editHandler, includeHandler, notDuplicateHandler } = props;
  const { castInfo, className, allowEditValues } = props;
  const { allowCheckBoxes, checkBoxes, toggleCheckboxes } = props;
  const [showDuplicates, setShowDuplicates] = useState(false);
  const [expandText, setExpandText] = useState(false);
  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  const toggleExpandText = () => setExpandText(!expandText);

  const toggleDuplicates = () => setShowDuplicates(!showDuplicates);
  return (
    <>
      <tr className={className}>
        <td>
          {props.index + 1}
          {deleteHandler && allowCheckBoxes ? (
            <input
              type="checkbox"
              className="form-control"
              onChange={() => toggleCheckboxes(message._id)}
              checked={checkBoxes[message._id]}
            />
          ) : null}
        </td>
        <td>
          <div>{message.channel?.name}</div>
          <div>
            <a
              href={`https://t.me/${message.channel.alias}/${message.ident}`}
              target="_blank"
              className="btn btn-sm btn-info"
            >
              <i className="fab fa-telegram-plane" />
            </a>
          </div>
        </td>
        {/* 
  <td className="text-nowrap">
    {message.fileId && <AudioPreview file={{ _id: message.fileId }} />}
    <CastMessageTextButton {...{ message }} />
  </td>
  */}
        <td>
          {allowEditValues ? (
            <FastEditMessageValue
              {...{ message, setMessage, castInfo, deleteHandler }}
              value="title"
            />
          ) : (
            message.title
          )}
        </td>
        <td
          className={`overflow-hidden ${expandText ? "" : "text-nowrap"}`}
          style={{ maxWidth: "250px" }}
        >
          <div>{message.text}</div>
          <div>
            <button className="btn btn-sm btn-secondary" onClick={toggleExpandText}>
              <i className={`fa ${!expandText ? "fa-expand-alt" : "fa-compress-alt"}`} />
            </button>
          </div>
        </td>
        <td className="text-nowrap">
          {allowEditValues ? (
            <FastEditMessageValue
              {...{ castInfo, deleteHandler, message, setMessage }}
              value="score"
            />
          ) : (
            message.score
          )}
        </td>

        <td>{message.ident}</td>
        <td>
          <ShowShortDuration {...message} />
        </td>
        <td>{moment(message.createdAt).format(dateTimeFormat)}</td>
        {/* <td>{message.status}</td> */}
        <td className="text-nowrap">
          {deleteHandler ? (
            <DeleteCastMessageButton {...{ castInfo, message }} successHandler={deleteHandler} />
          ) : null}
          {editHandler ? (
            <ChannelMessageForm {...{ message }} refreshHandler={editHandler} />
          ) : null}
          {includeHandler ? (
            <IncludeCastMessageButton {...{ castInfo, message }} successHandler={includeHandler} />
          ) : null}
          {notDuplicateHandler ? (
            <NotDuplicateCastMessageButton
              {...{ castInfo, message }}
              successHandler={notDuplicateHandler}
            />
          ) : null}
          {message.duplicates ? (
            <button className="btn btn-secondary btn-sm ml-2" onClick={toggleDuplicates}>
              <i className={`fa ${showDuplicates ? "fa-angle-up" : "fa-angle-down"}`} />
            </button>
          ) : null}
          <div>
            <small>{message.status}</small>
          </div>
        </td>
      </tr>
      {showDuplicates ? (
        <>
          <tr className="bg-secondary">
            <td colSpan={99} className="pt-1 pb-0"></td>
          </tr>
          {_.map(message.duplicates, (message, key) => {
            return <ShowMessagesTr {...{ message }} className={"bg-muted"} key={key} />;
          })}
          <tr className="bg-secondary">
            <td colSpan={99} className="pt-1 pb-0"></td>
          </tr>
        </>
      ) : null}
    </>
  );
};

const patchMessageValue = api.patch(apiUrls.editMessage);

const FastEditMessageValue = (props) => {
  const { castInfo, message, setMessage, value, deleteHandler } = props;

  const [editMessageValue, setEditMessageValue] = useState(false);
  const [messageSaving, setMessageSaving] = useState(false);

  const dispatch = useDispatch();

  const saveEditMessage = () => {
    const params = { channelId: message.channelId, messageId: message._id };
    const body = { [value]: message[value] };
    dispatch(
      patchMessageValue(
        { params, body },
        {
          onRequest() {
            setMessageSaving(true);
          },
          onSuccess() {
            // в случае успеха вызовем процедуру удаления с пустым списком сообщений, чтобы перестроить заголовки в выпуске
            const { scheduleId, _id: castId } = castInfo;
            const params = { scheduleId, castId };
            const body = { messagesId: [] };
            dispatch(
              castDeleteMessages(
                { params, body },
                {
                  onSuccess(body) {
                    setMessageSaving(false);
                    setEditMessageValue(false);
                    deleteHandler(body);
                  },
                  onRequest() {},
                }
              )
            );
          },
          onFailure(e) {
            setMessageSaving(false);
            restoreDefaultMessage();
          },
        }
      )
    );
  };
  const restoreDefaultMessage = () => {
    setEditMessageValue(false);
    setMessage(props.message);
  };

  return (
    <>
      {editMessageValue ? (
        <div className="">
          <div className="form-group">
            <input
              className="form-control"
              value={message[value]}
              onChange={({ target }) => setMessage({ ...message, [value]: target.value })}
            />
          </div>

          <div className="text-nowrap">
            <button
              className="btn btn-sm btn-success"
              onClick={saveEditMessage}
              disabled={messageSaving}
            >
              {messageSaving ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                <i className="fa fa-check" />
              )}
            </button>
            <button
              className="btn btn-sm btn-secondary mx-2"
              onClick={restoreDefaultMessage}
              disabled={messageSaving}
            >
              <i className="fa fa-ban" />
            </button>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="">{message[value]}</div>
          <div className="">
            <button className="btn btn-sm btn-info" onClick={() => setEditMessageValue(true)}>
              <i className="fa fa-edit" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const castRefreshCover = api.post(apiUrls.castRefreshCover);
export const CastRefreshImageButton = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { castInfo, successHandler } = props;
  const { scheduleId, _id: castId } = castInfo;
  const [body, setBody] = useState({});
  const [modalOpen, toggleModal] = useToggle(false);

  const changeBody = setOnChange(body, setBody);

  const submitHandler = () => {
    toggleModal();
    // const confirmed = window.confirm("Обновить изображение?");
    const params = { scheduleId, castId };
    // const body = { prompt };
    dispatch(
      castRefreshCover(
        { params, body },
        {
          onSuccess(body) {
            successHandler(body);
            setLoading(false);
          },
          onRequest() {
            setBody({});
            setLoading(true);
          },
        }
      )
    );
  };

  return (
    <>
      <button className="btn btn-sm btn-warning" onClick={toggleModal}>
        <i className={`fa fa-sync ${isLoading ? "fa-spin" : ""} mr-2`} />
        <IntlMessages id="OpenAI.Casts.refreshCover" />
      </button>
      {modalOpen ? (
        <Modal isOpen={modalOpen} toggle={toggleModal} size="lg">
          <ModalHeader toggle={toggleModal}>
            <IntlMessages id="OpenAI.Casts.refreshCoverPrompt" />
          </ModalHeader>
          <ModalBody>
            <textarea
              className="form-control"
              name="prompt"
              rows={2}
              value={body.prompt || ""}
              onChange={changeBody}
            />
            <br />
            <button className="btn btn-primary" onClick={submitHandler}>
              <IntlMessages id="confirm" />
            </button>
          </ModalBody>
        </Modal>
      ) : null}
    </>
  );
};

const castRefreshIntro = api.post(apiUrls.castRefreshIntro);
export const CastRefreshIntroButton = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const { castInfo, successHandler } = props;
  const { scheduleId, _id: castId } = castInfo;
  const [body, setBody] = useState({});

  const submitHandler = () => {
    const confirmed = window.confirm("Обновить трейлер?");
    const params = { scheduleId, castId };

    confirmed &&
      dispatch(
        castRefreshIntro(
          { params, body },
          {
            onSuccess(body) {
              successHandler(body);
              setLoading(false);
            },
            onRequest() {
              setBody({});
              setLoading(true);
              setError(false);
            },
            onFailure() {
              setBody({});
              setLoading(false);
              setError(true);
            },
          }
        )
      );
  };

  return (
    <>
      <button
        className={`btn btn-sm ${isError ? "btn-danger" : "btn-warning"}`}
        onClick={submitHandler}
      >
        <i className={`fa fa-sync ${isLoading ? "fa-spin" : ""} mr-2`} />
        <IntlMessages id="OpenAI.Casts.refreshIntro" />
      </button>
    </>
  );
};

const castScoringText = api.post(apiUrls.castScoringText);
export const CastScoringTextBlock = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const { castInfo, successHandler } = props;
  const { scheduleId, _id: castId } = castInfo;

  const [showControl, setShowControl] = useState(false);
  const [text, setText] = useState(castInfo.scoringText);

  useEffect(() => {
    setText(castInfo.scoringText);
  }, [castInfo]);

  const submitHandler = () => {
    const confirmed = window.confirm("Обновить текст для оценки?");
    const params = { scheduleId, castId };
    const body = { text };
    confirmed &&
      dispatch(
        castScoringText(
          { params, body },
          {
            onSuccess(body) {
              successHandler(body);
              setLoading(false);
            },
            onRequest() {
              setLoading(true);
              setError(false);
            },
            onFailure() {
              setLoading(false);
              setError(true);
            },
          }
        )
      );
  };

  return (
    <>
      <button className="btn btn-secondary" onClick={() => setShowControl(!showControl)}>
        <IntlMessages id="OpenAI.Casts.scoringText" />{" "}
        {showControl ? <i className="fa fa-chevron-up" /> : <i className="fa fa-chevron-down" />}
      </button>

      {showControl ? (
        <div>
          <textarea
            className="form-control my-2"
            rows={5}
            onChange={({ target }) => setText(target.value)}
          >
            {text}
          </textarea>
          <button
            className={`btn btn-sm ${isError ? "btn-danger" : "btn-warning"}`}
            onClick={submitHandler}
          >
            <i className={`fa  ${isLoading ? "fa-sync fa-spin" : "fa-save"} mr-2`} />
            <IntlMessages id="OpenAI.Casts.setScoringText" />
          </button>
        </div>
      ) : null}
    </>
  );
};

const castThreadLogs = api.get(apiUrls.castThreadLogs);

const CastPublicationInfo = (props) => {
  const { publication } = props.castInfo;
  const intl = useIntl();
  const [showData, setShowData] = useState(false);
  const toggleShow = () => setShowData(!showData);
  if (publication) {
    return (
      <div>
        <button className="btn btn-info" onClick={toggleShow}>
          Публикация{" "}
          {showData ? <i className="fa fa-chevron-up" /> : <i className="fa fa-chevron-down" />}
        </button>
        {showData ? (
          <div>
            {publication.resultFileId ? (
              <div className="row">
                <div className="col-4">Mp3</div>
                <div className="col">
                  <AudioPreview file={{ _id: publication.resultFileId }} />
                </div>
              </div>
            ) : null}

            {publication.resultPostCoverId ? (
              <div className="row">
                <div className="col-4">Обложка</div>
                <div className="col">
                  <ImagePreview file={{ _id: publication.resultPostCoverId }} />
                </div>
              </div>
            ) : null}

            {publication.resultVideoId ? (
              <div className="row">
                <div className="col-4">Видео</div>
                <div className="col">
                  <VideoPreview file={{ _id: publication.resultVideoId }} />
                </div>
              </div>
            ) : null}

            {publication.resultTrailerVideoId ? (
              <div className="row">
                <div className="col-4">Видео трейлер</div>
                <div className="col">
                  <VideoPreview file={{ _id: publication.resultTrailerVideoId }} />
                </div>
              </div>
            ) : null}

            {publication.resultStoryVideoId ? (
              <div className="row">
                <div className="col-4">Видео сториз</div>
                <div className="col">
                  <VideoPreview file={{ _id: publication.resultStoryVideoId }} />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  } else {
    return null;
  }
};
const CastThreadLogsButton = (props) => {
  const dispatch = useDispatch();
  const { castInfo } = props;
  const [isLoading, setLoading] = useState(false);
  const [logsData, setLogsData] = useState([]);
  const intl = useIntl();
  const { scheduleId, _id: castId } = castInfo;
  const dateTimeFormat = intl.formatMessage({ id: "dateTimeFormat" });

  const closeModal = () => setLogsData([]);

  const clickHandler = () => {
    const params = { scheduleId, castId };
    dispatch(
      castThreadLogs(
        { params },
        {
          onSuccess(body) {
            setLogsData(body);
            setLoading(false);
          },
          onRequest() {
            setLogsData([]);
            setLoading(true);
          },
        }
      )
    );
  };

  return (
    <>
      {castInfo.threadId ? (
        <>
          <button className="btn btn-sm btn-info" onClick={clickHandler}>
            <i className={`fa ${isLoading ? "fa-spin fa-spinner" : "fa-list"} mr-2`} />
            {intl.formatMessage({ id: "OpenAI.Casts.threadLogs" })}
          </button>
          {logsData.length ? (
            <Modal isOpen={true} size="xl" toggle={closeModal}>
              <ModalHeader toggle={closeModal}>
                {intl.formatMessage({ id: "OpenAI.Casts.threadLogs" })}
              </ModalHeader>
              <ModalBody>
                {logsData.map((log) => {
                  return (
                    <div className="mb-2">
                      <div className="mb-2">{moment(log.date).format(dateTimeFormat)}</div>
                      <Markdown>{log.text}</Markdown>
                    </div>
                  );
                })}
              </ModalBody>
            </Modal>
          ) : null}
        </>
      ) : null}
    </>
  );
};

const castPublish = api.post(apiUrls.castPublish);
const castReject = api.post(apiUrls.castReject);
const castCancel = api.post(apiUrls.castCancel);
const castRestore = api.post(apiUrls.castRestore);

const CastChangeStatusButton = (props) => {
  const dispatch = useDispatch();
  const { castInfo, successHandler, refreshHandler } = props;
  const [isLoading, setLoading] = useState(false);
  const intl = useIntl();
  const { scheduleId, _id: castId } = castInfo;

  const clickHandler = (actionHandler) => () => {
    const confirmed = window.confirm("Выполнить действие?");
    if (confirmed) {
      const params = { scheduleId, castId };
      const body = {};
      dispatch(
        actionHandler(
          { params, body },
          {
            onSuccess(body) {
              successHandler(body);
              setLoading(false);
              refreshHandler();
            },
            onRequest() {
              setLoading(true);
            },
          }
        )
      );
    }
  };

  return !isLoading ? (
    <div className="my-auto">
      {castInfo.status === "completed" ? (
        <>
          <button className="btn btn-sm btn-success m-1" onClick={clickHandler(castPublish)}>
            <i className={`fa fa-check mr-2`} />
            {intl.formatMessage({ id: "OpenAI.Casts.approve" })}
          </button>
          <button className="btn btn-sm btn-danger m-1" onClick={clickHandler(castReject)}>
            <i className={`fa fa-ban mr-2`} />
            {intl.formatMessage({ id: "OpenAI.Casts.reject" })}
          </button>
        </>
      ) : (
        <>
          <span className="btn">
            {intl.formatMessage({ id: `OpenAI.Casts.status.${castInfo.status}` })}
          </span>
          {castInfo.status === "published" ? (
            <button className="btn btn-sm btn-danger m-1" onClick={clickHandler(castCancel)}>
              <i className={`fa fa-times mr-2`} />
              {intl.formatMessage({ id: "OpenAI.Casts.cancel" })}
            </button>
          ) : null}
          {castInfo.status === "rejected" ? (
            <button className="btn btn-sm btn-secondary m-1" onClick={clickHandler(castRestore)}>
              <i className={`fa fa-undo mr-2`} />
              {intl.formatMessage({ id: "OpenAI.Casts.restore" })}
            </button>
          ) : null}
        </>
      )}
    </div>
  ) : (
    <LoadingIcon />
  );
};

const castCompilation = api.post(apiUrls.castCompilation);

const CastCompilationButton = (props) => {
  const dispatch = useDispatch();
  const { castInfo } = props;
  const [isLoading, setLoading] = useState(false);
  const intl = useIntl();
  const { scheduleId, _id: castId } = castInfo;

  const clickHandler = (actionHandler) => () => {
    const confirmed = window.confirm("Выполнить действие?");
    if (confirmed) {
      const params = { scheduleId, castId };
      const body = {};
      dispatch(
        actionHandler(
          { params, body },
          {
            onSuccess(body) {
              console.log(body);
              // successHandler(body);
              setLoading(false);
              // refreshHandler();
            },
            onRequest() {
              setLoading(true);
            },
          }
        )
      );
    }
  };

  return !isLoading ? (
    <button className="btn btn-sm btn-warning mx-2" onClick={clickHandler(castCompilation)}>
      <i className={`fa fa-check mr-2`} />
      {intl.formatMessage({ id: "OpenAI.Casts.compile" })}
    </button>
  ) : (
    <LoadingIcon />
  );
};

export const CastMessageTextButton = (props) => {
  const { message } = props;
  const id = `tooltip_${message._id}`;
  return (
    <>
      <button className="btn btn-sm btn-secondary" id={id}>
        <i className={`fa fa-text`} />
      </button>

      <UncontrolledPopover
        // className="roy-menu"
        // innerClassName="roy-inner-content"
        placement="right"
        target={id}
        trigger="legacy"
      >
        <PopoverBody>
          <div className="overflow-auto" style={{ maxHeight: "150px" }}>
            {message.text}
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

const castDeleteMessages = api.post(apiUrls.castDeleteMessages);
export const DeleteCastMessageButton = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { castInfo, message, messagesId, successHandler } = props;
  const { scheduleId, _id: castId } = castInfo;
  const clickHandler = () => {
    const confirmed = window.confirm("Удалить из выпуска?");
    if (confirmed) {
      const params = { scheduleId, castId };
      const body = messagesId ? { messagesId } : { messagesId: [message._id] };
      dispatch(
        castDeleteMessages(
          { params, body },
          {
            onSuccess(body) {
              successHandler(body);
              setLoading(false);
            },
            onRequest() {
              setLoading(true);
            },
          }
        )
      );
    }
  };

  return (
    <button className="btn btn-sm btn-danger mr-2" onClick={clickHandler}>
      <i className={`fa ${isLoading ? "fa-spin fa-spinner" : "fa-times"}`} />
    </button>
  );
};

const castIncludeMessages = api.post(apiUrls.castIncludeMessages);
export const IncludeCastMessageButton = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { castInfo, message, successHandler } = props;
  const { scheduleId, _id: castId } = castInfo;
  const clickHandler = () => {
    const confirmed = window.confirm("Включить сообщение в выпуск?");
    if (confirmed) {
      const params = { scheduleId, castId };
      const body = { messagesId: [message._id] };
      dispatch(
        castIncludeMessages(
          { params, body },
          {
            onSuccess(body) {
              successHandler(body);
              setLoading(false);
            },
            onRequest() {
              setLoading(true);
            },
          }
        )
      );
    }
  };

  return (
    <button className="btn btn-sm btn-primary mr-2" onClick={clickHandler}>
      <i className={`fa ${isLoading ? "fa-spin fa-spinner" : "fa-check"}`} />
    </button>
  );
};

const sendSummaryNotification = api.post(apiUrls.summaryNotifications);
const makeSuperCover = api.post(apiUrls.superCover);
export const SpecialButtonsBlock = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { castInfo, successHandler } = props;
  const { scheduleId, _id: castId } = castInfo;
  const notificationsClickHandler = () => {
    const confirmed = window.confirm("Отправить уведомление?");
    if (confirmed) {
      const params = { scheduleId, castId };
      // const body = { messagesId: [message._id] };
      dispatch(
        sendSummaryNotification(
          { params },
          {
            onSuccess(body) {
              // successHandler(body);
              setLoading(false);
            },
            onRequest() {
              setLoading(true);
            },
          }
        )
      );
    }
  };
  const superCoverClickHandler = () => {
    const confirmed = window.confirm("Сделать супер обложку?");
    if (confirmed) {
      const params = { scheduleId, castId };
      // const body = { messagesId: [message._id] };
      dispatch(
        makeSuperCover(
          { params },
          {
            onSuccess(body) {
              // successHandler(body);
              setLoading(false);
            },
            onRequest() {
              setLoading(true);
            },
          }
        )
      );
    }
  };

  return (
    <div className="row">
      <div className="col">
        <button className="btn btn-sm btn-primary mr-2" onClick={notificationsClickHandler}>
          <i className={`fa ${isLoading ? "fa-spin fa-spinner" : "fa-envelope"}`} />
        </button>
      </div>
      <div className="col">
        <button className="btn btn-sm btn-primary mr-2" onClick={superCoverClickHandler}>
          <i className={`fa ${isLoading ? "fa-spin fa-spinner" : "fa-image"}`} />
        </button>
      </div>
    </div>
  );
};

const castNotDuplicateMessages = api.post(apiUrls.castNotDuplicateMessages);
export const NotDuplicateCastMessageButton = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const { castInfo, message, successHandler } = props;
  const { scheduleId, _id: castId } = castInfo;
  const clickHandler = () => {
    const confirmed = window.confirm("Включить сообщение в выпуск?");
    if (confirmed) {
      const params = { scheduleId, castId };
      const body = { messagesId: [message._id] };
      dispatch(
        castNotDuplicateMessages(
          { params, body },
          {
            onSuccess(body) {
              successHandler(body);
              setLoading(false);
            },
            onRequest() {
              setLoading(true);
            },
          }
        )
      );
    }
  };

  return (
    <button className="btn btn-sm btn-warning mr-2" onClick={clickHandler}>
      <i className={`fa ${isLoading ? "fa-spin fa-spinner" : "fa-check"}`} />
    </button>
  );
};

const getCastInfo = api.get(apiUrls.castInfo);
export const GeneratedCastInfoButton = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  const { cast, refreshHandler } = props;
  // const { scheduleId, _id: castId } = cast;
  const [isLoading, setLoading] = useState(false);
  const [castInfo, setCastInfo] = useState(null);

  const closeHandler = () => {
    history.push("#");
  };

  const loadCastInfo = (castId) => {
    dispatch(
      getCastInfo(
        { params: { ...params, castId } },
        {
          onSuccess(body) {
            //
            setCastInfo(body);
            setLoading(false);
          },
          onRequest() {
            //
            setCastInfo(null);
            setLoading(true);
          },
        }
      )
    );
  };

  useEffect(() => {
    const { castId } = qs.parse(location.hash);
    if (castId === cast._id) {
      loadCastInfo(castId);
    } else {
      setCastInfo(null);
    }
  }, [location.hash]);

  const clickHandler = () => {
    history.push(`#castId=${cast._id}`);
  };
  return (
    <>
      <button className="btn btn-sm btn-info" onClick={clickHandler}>
        <i className={`fa ${isLoading ? "fa-spin fa-spinner" : "fa-info-circle"}`} />
      </button>
      {castInfo ? (
        <>
          <Modal size="xl" isOpen={true} toggle={closeHandler}>
            <ModalHeader toggle={closeHandler}></ModalHeader>
            <ModalBody>
              <GeneratedCastInfo {...{ castInfo, setCastInfo, refreshHandler, loadCastInfo }} />
            </ModalBody>
          </Modal>
        </>
      ) : null}
    </>
  );
};

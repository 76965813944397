/** управление данными для сайта
 */
import _ from "lodash";
import React, { useEffect } from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { Error400 } from "views/pages";
import IntlMessages from "util/intlMessages";

import Countries from "./Countries";
import Categories from "./Categories";
import Locales from "./Locales";
import { BreadCrumbs } from "components/standart/PageTitle";
import Premiums from "./Premiums";
import Partners from "./Partners";
import TelegramAccounts from "./Accounts";
import AdvertsIntros from "./Intros";
import Replacements from "./Replacements";
import StopWords from "./StopWords";
import FilterWords from "./FilterWords";
import ImageStyles from "./ImageStyles";
import SpecialsCategories from "./SpecialsCategories";
import PublishChannels from "./PublishChannels";

import { path } from "./init";
import DataNodes from "./DataNodes";

const Contents = (props) => {
  const { url, path } = useRouteMatch();
  const { breadCrumbs = new BreadCrumbs() } = props;

  breadCrumbs.push({
    title: <IntlMessages id="Contents" />,
    url,
  });
  return (
    <div className="react-strap-doc">
      <Switch>
        <Route path={`${path}/countries`}>
          <Countries {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/dataNodes`}>
          <DataNodes {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/publishChannels`}>
          <PublishChannels {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/locales`}>
          <Locales {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/categories`}>
          <Categories {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/specials_categories`}>
          <SpecialsCategories {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/premiums`}>
          <Premiums {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/intros`}>
          <AdvertsIntros {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/replacements`}>
          <Replacements {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/stopwords`}>
          <StopWords {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/filterwords`}>
          <FilterWords {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/styles`}>
          <ImageStyles {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/${TelegramAccounts.path}`}>
          <TelegramAccounts {...{ breadCrumbs }} />
        </Route>

        <Route path={`${path}/${Partners.path}`}>
          <Partners {...{ breadCrumbs }} />
        </Route>

        <Route exact path={path} render={() => <Redirect to={`${path}/countries`} />} />

        <Route component={Error400} />
      </Switch>
    </div>
  );
};

export default { path, component: Contents };
